.siteContent {
    height: calc(75%);
    width: 100%;
}
.siteContent .main {
    position: absolute;
    top: calc(50% - 233px);
    left: calc(50% - 185px);
    width: 369px;
    height: 366px;
}
.enter-site-btn {
    cursor: pointer;
    display: block;
    border: 0;
    width: 369px;
    height: 366px;
    background: url("./button_Enter.png") top no-repeat;
    margin: 0 auto;
}
.enter-site-btn:hover {
    background-position: bottom;
}

#site {
    background-color: #6363f9;
    margin: 0;
    padding: 0;
    direction: rtl;
    font-family: arial;
    height: 100%;
}
.footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    background-color: #211e87;
    width: 100%;
}
.footer-center {
    text-align: center;
    font-size: 90%;
    color: #fff;
    padding-top: 10px;
    line-height: 1.3em;
}
.message-center {
    text-align: center;
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
}
