@font-face {
    font-family: "ProntoMF";
    src: url("/fonts/ProntoMFMedium.woff2") format("woff2"), url("/fonts/ProntoMFMedium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "ProntoMF";
    src: url("/fonts/ProntoMFBold.woff2") format("woff2"), url("/fonts/ProntoMFBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Choco";
    src: url("/fonts/ChocoBold.woff2") format("woff2"), url("/fonts/ChocoBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Choco";
    src: url("/fonts/Choco.woff2") format("woff2"), url("/fonts/Choco.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "NarkisTamMFO";
    src: url("/fonts/NarkisTamMFO.woff2") format("woff2"), url("/fonts/NarkisTamMFO.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "NarkisTamMFO";
    src: url("/fonts/NarkisTamMFOMedium.woff2") format("woff2"), url("/fonts/NarkisTamMFOMedium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "NarkisTamMFO";
    src: url("/fonts/NarkisTamMFOBold.woff2") format("woff2"), url("/fonts/NarkisTamMFOBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "FbKingFox Bold";
    src: url("/fonts/FbKingFox-Bold.woff2") format("woff2"), url("/fonts/FbKingFox-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "FbKingFox Regular";
    src: url("/fonts/FbKingFoxRegular.woff2") format("woff2"), url("/fonts/FbKingFoxRegular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "gas";
    src: url("/fonts/gas.woff2") format("woff2"), url("/fonts/gas.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "arial-black";
    src: url("/fonts/arial-black.woff2") format("woff2"), url("/fonts/arial-black.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Helvetica";
    src: url("/fonts/HelveticaRoundedLTStd-Black.woff2") format("woff2"),
        url("/fonts/HelveticaRoundedLTStd-Black.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "MV Boli";
    src: url("/fonts/mvboli.woff2") format("woff2"), url("/fonts/mvboli.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Karnaf-Bold";
    src: url("/fonts/Karnaf-Bold.woff2") format("woff2"), url("/fonts/Karnaf-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Karnaflight";
    src: url("/fonts/Karnaflight.woff2") format("woff2"), url("/fonts/Karnaflight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
}

canvas {
    direction: rtl;
}

canvas.print {
    display: none !important;
}

@page {
    size: A4 landscape;
    margin: 0;
}

@media print {
    canvas {
        display: none !important;
    }

    .creation_corner #root {
        background: #a2b0f6;
        -webkit-print-color-adjust: exact;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    canvas.print {
        display: block !important;
        position: initial !important;
    }
}
