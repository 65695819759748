/* .pause {
    background: url(http://cdn1.iconfinder.com/data/icons/snowish/128x128/actions/gtk-media-pause.png)
        no-repeat center !important;
    display: none;
} */

.controlButton {
    opacity: 10;
    height: 107px;
}
