.only_print {
    display: none;
    @media print {
        & {
            display: block;
        }
    }
}
.footer_english {
    position: fixed;
    left: 40pt;
    bottom: 3pt;
    font-size: 15pt;
    margin: 3%;
    display: none;
    @media print {
        & {
            display: initial;
        }
    }
}
.footer_Hebrew {
    display: initial;
    position: fixed;
    right: 60pt;
    bottom: 1pt;
    font-family: "choco";
    font-size: 20pt;
    margin: 3%;
    opacity: 0;
    @media print {
        & {
            opacity: 1;
        }
    }
}
