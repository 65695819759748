.footer_english {
    display: none;
    @media print {
        & {
            display: initial;
            position: fixed;
            left: 40pt;
            bottom: 3pt;
            font-size: 20pt;
            margin: 3%;
        }
    }
}
.footer_Hebrew {
    display: none;
    @media print {
        & {
            display: initial;
            position: fixed;
            right: 60pt;
            bottom: 1pt;
            font-family: "choco";
            font-size: 25pt;
            margin: 3%;
        }
    }
}
