.preloader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
