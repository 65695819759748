.input {
    //font-size: 23px;
    border: none;
    //color: transparent;
    background-color: transparent;
    outline: none;
    // font-family: Arial;
    // font-weight:bold;
    color: #790079;
}
