.svg,
.form {
    width: 100%;
    height: 100%;
    position: relative;
}

.message {
    x: -80px;
    y: -110px;
    width: 420px;
    height: 300px;
}

.messageTxt {
    direction: rtl;
    text-align: center;
    font-family: "Choco";
    font-size: 25px;
    color: #660000;
}

@mixin input {
    box-sizing: border-box;
    padding: 4px 8px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    font-weight: 700;
    &:focus {
        outline: unset;
    }
}

.username,
.password {
    @include input;
    font-size: 17px;
    color: #790079;
    direction: rtl;
}

.email {
    @include input;
    font-size: 15px;
    color: #f55d9d;
}

.foreign_username,
.foreign_password {
    width: 164px;
    height: 25px;
}

.foreign_email {
    width: 254px;
    height: 25px;
    transform: matrix(0.645903966, 0, 0, 1, 0, 0);
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(124, 122, 122, 0.5);
}

.wraper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .contaner {
        position: relative;
        margin: 0 auto;
        width: 192em;
        height: 108em;
        background-image: url(../loginnew/login.svg);
        background-size: contain;
        background-repeat: no-repeat;
        user-select: none;
        font-family: "Open Sans Hebrew";
        .logo {
            background-image: url(../loginnew/main_logo.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: 27.7em;
            height: 24.8em;
            position: absolute;
            top: 0em;
            right: 13.7em;
        }
        .back_btn {
            background-image: url(../loginnew/back_btn.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: 4.6em;
            height: 4.6em;
            cursor: pointer;
            position: absolute;
            top: 2em;
            left: 2em;
            border-radius: 4.6em;
        }
        .form {
            width: 68.1em;
            height: 36em;
            border-radius: 1.1em;
            background-color: #ffffff;
            position: absolute;
            top: 28.2em;
            left: 60.6em;
            .form_bg {
                background-image: url(../loginnew/login_main_background.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: 65.7em;
                height: 24.5em;
                position: absolute;
                bottom: 0em;
                left: -1em;
            }
            .giraff {
                background-image: url(../loginnew/giraff.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: 18.2em;
                height: 36.2em;
                position: absolute;
                top: -0.5em;
                left: -1.5em;
            }

            div.member {
                left: 50%;
                position: absolute;
                top: 9em;
                transform: translate(-50%, 0);
                span {
                    font-size: 4.7em;
                    font-weight: bold;
                    color: #97319f;
                }
            }
            div.btn_enter {
                left: 50%;
                position: absolute;
                top: 16.8em;
                height: 6em;
                width: 28.1em;
                border-radius: 4.2em;
                background-color: #93289b;
                cursor: pointer;
                transform: translate(-50%, 0);
                line-height: 6em;
                text-align: center;
                span {
                    left: 50%;
                    font-size: 3.6em;
                    font-weight: bold;
                    color: #ffffff;
                }
            }

            div.buy_link {
                left: 50%;
                position: absolute;
                top: 25em;
                transform: translate(-50%, 0);
                cursor: pointer;
                span {
                    font-size: 2.6em;
                    color: #97319f;
                    text-decoration: underline;
                }
            }
        }
        .mod {
            width: 68.1em;
            height: 6em;
            border-radius: 1.1em;
            background-color: #ffffff;
            background-image: url(../loginnew/mod_background.svg);
            background-size: 65.7em 19.9em;
            background-repeat: no-repeat;
            background-position: right;
            position: absolute;
            top: 65.3em;
            left: 60.6em;
            cursor: pointer;

            div span {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                font-size: 2.4em;
                color: #f9b33b;
            }
        }
        .label {
            color: #ffffff;
            position: absolute;
            top: 10em;
            left: 20em;
            span {
                font-size: 3em;
            }
        }
    }
}
