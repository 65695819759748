@mixin responsive($name, $w, $h) {
    background: url("./" + $name + "/bg.jpg") center / contain no-repeat;
    img {
        width: $w;
        height: $h;
        object-fit: scale-down;
    }
}

.preloader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.plain {
        @include responsive("plain", 40%, 20%);
    }
    &.meter {
        @include responsive("meter", 20%, 20%);
    }

    &.boat {
        background: url(./boat/bg.jpg) center no-repeat;
    }
    &.maya {
        background: url(./maya/bg.jpg) center no-repeat;
        img {
            margin-top: 90px;
        }
    }
}
