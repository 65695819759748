.only_print {
    display: none;
    @media print {
        & {
            display: block;
            break-after: page;
        }
    }
}
.footer_english {
    position: fixed;
    left: 40pt;
    bottom: 3pt;
    font-size: 15pt;
    margin: 3%;

    display: none;
    @media print {
        & {
            display: initial;
        }
    }
}
.footer_Hebrew {
    font-family: "choco";
    font-size: 20pt;
    position: fixed;
    right: 60pt;
    bottom: 1pt;
    margin: 3%;

    opacity: 0;
    @media print {
        & {
            opacity: 1;
        }
    }
}
.preloader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
